import * as React from 'react';
import FInput, { FInputProps } from '../f-input/f-input';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { KeyFilterType } from 'primereact/keyfilter';
import '../f-input/f-input.css';

export default function FTextInput(props: FInputProps): React.ReactElement {
  const baseInput = new FInput();
  const [suffixValue, setSuffixValue] = React.useState(baseInput.setSuffix(props.value, props.suffix));

  React.useEffect((): any => {
    if (props.autofocus) {
      setTimeout(() => {
        baseInput.focusElement(props.id);
      }, 100);
    }
  }, []);

  /**
   * Event onChange
   */
  const onChange = (val: any): void => {
    if (props.displayFormat && props.displayFormat['textCase'] === 'uppercase') {
      val = String(val).toUpperCase();
    }
    setSuffixValue(baseInput.setSuffix(val, props.suffix));
    baseInput.onChange(val, props.onValueChange);
  };

  /**
   * Manage mask based on the FormatCode in displayFormat
   */
  const getFormatMaskFromDisplayFormat = (): string | null => {
    if (props.displayFormat && props.displayFormat['mask']) {
      return props.displayFormat['mask'].replace(/ /g, '#').replace(/&/g, ' ');
    }
    return null;
  };

  /**
   * keyFilter is used to mimic the IBM Keyboard Shift feature
   */
  const getKeyFilter = (): KeyFilterType => {
    let result: KeyFilterType = /[^]/; // All characters by default are accepted (keyboard shift BLANK)

    if (props.displayFormat && props.displayFormat['filter']) {
      // Only number and characters applicable to a number are accepted (123,456.78) (keyboard shift D)
      if (props.displayFormat['filter'].toLowerCase() === 'digit') {
        result = /^[0-9]/;
      }

      // Only alphabetic characters (keyboard shift X)
      if (props.displayFormat['filter'].toLowerCase() === 'alphabetic') {
        result = /^[A-Za-z,.-\s]/;
      }

      // Only number without dot, coma and other characters (keyboard shift Y)
      if (props.displayFormat['filter'].toLowerCase() === 'filterednumeric') {
        result = /^[0-9,.+-\s]/;
      }

      // Only number without dot, coma and other characters (keyboard shift M)
      if (props.displayFormat['filter'].toLowerCase() === 'numericstring') {
        result = /^[0-9,.+-]/;
      }
    }

    return result;
  }

  /**
   * Render an InputMask as component
   */
  const renderInputMask = (mask: string): React.ReactElement => {
    return (
      <React.Fragment>
        <div className={baseInput.getInputWrapperClasses(props) + ' f-text'}>
          {props.label && <label htmlFor={props.name}>{props.label}</label>}
          <div className={baseInput.getResizableContainerClasses(props) + ' resizable-container'}>
            <InputMask
              className={props.displayFormat && props.displayFormat['textCase'] === 'uppercase' ? 'fp-inputtext uppercase' : 'fp-inputtext'}
              id={props.name}
              value={props.value ? props.value : ''}
              placeholder={props.placeholder}
              mask={mask}
              unmask={true}
              readOnly={props.readonly}
              disabled={props.protect}
              maxLength={props.displayFormat && props.displayFormat['textLength']
                ? props.displayFormat['textLength'] : props.maxlength}
              tooltip={props.tooltip}
              onChange={(e: any): void => { onChange(e.target.value); }}
              onFocus={(e: any): void => baseInput.onFocus(e, props.onFocus)}
              onBlur={(e: any): void => baseInput.onBlur(e, props.onBlur)}
              onClick={(e: any): void => { if (props.useCmdkeyClick && !props.readonly) { baseInput.onCmdkeyClick(e, props.cmdkey || '', props.onCmdkeyClick); } }}
            />

            {props.suffix !== undefined && <span className={'suffix'}>{suffixValue}</span>}

            {
              props.promptable &&
              !props.protect &&
              (
                <Button
                  id={props.name + '-promptable'}
                  className={'promptable-btn'}
                  icon="pi pi-search"
                  onClick={(e: any): void => {
                    e.preventDefault();
                    baseInput.onPrompt(e, props.onPrompt);
                  }}
                />
              )
            }

            {props.required && <span className={'required-symbol'}>*</span>}
          </div>
        </div>
        {props.error && props.error !== '' &&
          <span className={'error-msg'}>{props.error}</span>
        }
      </React.Fragment>
    );
  }

  /**
   * Render an InputText as component
   */
  const renderInputText = (): React.ReactElement => {
    return (
      <React.Fragment>
        <div className={baseInput.getInputWrapperClasses(props) + ' f-text'}>
          {props.label && <label htmlFor={props.name}>{props.label}</label>}
          <div className={baseInput.getResizableContainerClasses(props) + ' resizable-container'}>
            <InputText
              className={props.displayFormat && props.displayFormat['textCase'] === 'uppercase' ? 'fp-inputtext uppercase' : 'fp-inputtext'}
              id={props.name}
              value={props.value ? props.value : ''}
              placeholder={props.placeholder}
              readOnly={props.readonly}
              disabled={props.protect}
              maxLength={props.displayFormat && props.displayFormat['textLength']
                ? props.displayFormat['textLength'] : props.maxlength}
              tooltip={props.tooltip}
              {...(props.displayFormat && props.displayFormat['filter'] && { keyfilter: getKeyFilter() })}
              onChange={(e: any): void => { onChange(e.target.value); }}
              onFocus={(e: any): void => baseInput.onFocus(e, props.onFocus)}
              onBlur={(e: any): void => baseInput.onBlur(e, props.onBlur)}
              onClick={(e: any): void => { if (props.useCmdkeyClick && !props.readonly) { baseInput.onCmdkeyClick(e, props.cmdkey || '', props.onCmdkeyClick); } }}

              onKeyDown={(e: any): void => {
                if (props.onKeyDown) {
                  props.onKeyDown(e);
                }
              }}
            />

            {props.suffix !== undefined && <span className={'suffix'}>{suffixValue}</span>}

            {
              props.promptable &&
              !props.protect &&
              (
                <Button
                  id={props.name + '-promptable'}
                  className={'promptable-btn'}
                  icon="pi pi-search"
                  onClick={(e: any): void => {
                    e.preventDefault();
                    baseInput.onPrompt(e, props.onPrompt);
                  }}
                />
              )
            }
            {props.required && <span className={'required-symbol'}>*</span>}
          </div>
        </div>
        {props.error && props.error !== '' &&
          <span className={'error-msg'}>{props.error}</span>
        }
      </React.Fragment>
    );
  }

  /**
   * Render input component
   */
  const renderInput = (): React.ReactElement => {
    const mask = getFormatMaskFromDisplayFormat();

    // Necessary because of bug prime react that cannot accept null nask
    if (mask) {
      return renderInputMask(mask);
    } else {
      return renderInputText();
    }
  };

  return renderInput();
}
