import * as React from 'react';
import { Button, ButtonPositionType } from 'primereact/button';
import './f-action-button.css';

export interface FActionButtonProps {
  title?: string;
  tooltip?: string;
  tooltipOptions?: object;
  icon?: string;
  iconPos?: ButtonPositionType;
  disabled?: boolean;
  condition?: object;
  className?: string;
  imageAltText?: string;
  image?: string;
  imagePos?: string;
  action: () => any;
}

export default function FActionButton(props: FActionButtonProps): React.ReactElement {
  const renderImage = (): React.ReactElement => {
    if (props.image) {
      return (
        <React.Fragment>
          <img alt={props.imageAltText ? props.imageAltText : ''} src={props.image} className={`custom-image${props.imagePos ? ' ' + props.imagePos : ''}`} />
        </React.Fragment>
      );
    }

    return <></>;
  };

  return (
    <Button
      className={`legacy-action-button${props.className ? ' ' + props.className : ''}`}
      disabled={props.disabled}
      tooltip={props.tooltip}
      tooltipOptions={props.tooltipOptions ? props.tooltipOptions : { position: 'top' }}
      onClick={!props.disabled ? props.action : function foo() {
        // This is intentional
      }}
      icon={props.icon}
      iconPos={props.iconPos}
      label={props.title}
    >
      {renderImage()}
    </Button>
  );
}
