import * as React from 'react';
import FInput, { FInputProps } from '../f-input/f-input';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import NumberFormat from 'react-number-format'
import '../f-input/f-input.css';
import { KeyFilterType } from 'primereact/keyfilter';

export interface FNumericInputProps extends FInputProps {
  /**
   * if the input allows decimals
   */
  decimal?: boolean;
  /**
   * if the input forces positive values
   */
  positive?: boolean;

  formatted?: boolean;
}

const FNumericInput: React.FunctionComponent<FNumericInputProps> = (props: FNumericInputProps): React.ReactElement => {
  const baseInput = new FInput();
  const [suffixValue, setSuffixValue] = React.useState(baseInput.setSuffix(props.value, props.suffix));
  let maxValue = -1;
  let userkeyDownDetected = false;

  React.useEffect((): any => {
    if (props.autofocus) {
      setTimeout(() => {
        baseInput.focusElement(props.id);
      }, 100);
    }
  });

  React.useEffect((): any => {
    if (props.displayFormat) {
      // If formatCode is not special format then we set a max value...
      if ((props.displayFormat['formatCode'] == null || props.displayFormat['formatCode'].length === 0) || (props.displayFormat['formatCode'] && ['/', 'W', 'P'].includes(props.displayFormat['formatCode']) === false)) {
        if (props.displayFormat['nbDigits'] && !props.displayFormat['nbDecimals']) {
          maxValue = Number(''.padStart(props.displayFormat['nbDigits'], '9'));
        } else if (props.displayFormat['nbDigits'] && props.displayFormat['nbDecimals']) {
          maxValue = Number(''.padStart(props.displayFormat['nbDigits'] - props.displayFormat['nbDecimals'], '9'));
          maxValue = Number(String(maxValue) + '.' + ''.padStart(props.displayFormat['nbDecimals'], '9'));
        }
      }
    }
  });

  /* TO REVISE
  const zeroAsBlank = (value: any): any => {
    if (props.displayFormat && props.displayFormat['zeroAsBlank']) {
      if (Number(value) === 0) {
        return '';
      }
    }
    return value;
  };
*/

  // Manage mask based on the FormatCode in displayFormat
  const getFormatMaskFromDisplayFormat = (): any => {
    if (props.displayFormat) {
      if (props.displayFormat['formatCode']) {
        const formatCode = props.displayFormat['formatCode'];

        switch (formatCode) {
          case 'P': {
            if (props.displayFormat['nbDigits'] && props.displayFormat['nbDigits'] === 7) {
              // Phone displayed as 7 digits
              return '###-####';
            } else {
              // Phone displayed with full american digits
              return '###/###-####';
            }
          }
          case 'W': {
            if (props.displayFormat['nbDigits'] && props.displayFormat['nbDigits'] === 6) {
              // Numeric date is yy/mm/dd
              return '##/##/##';
            } else {
              // Numeric date is yyyy/mm/dd
              return '##/##/####';
            }
          }
          case '/': {
            if (props.displayFormat['nbDigits'] && props.displayFormat['nbDigits'] === 8) {
              // Only 8 digits then its a date only
              return '####/##/##';
            } else {
              // else we assume that we have the time part
              return '####/##/##/##:##:##';
            }
          }
        }
      } else if (props.displayFormat['mask']) {
        // Mask is based on the IBM EDTWRD (Edit Word) keyword. https://www.ibm.com/docs/en/i/7.4?topic=80-edtwrd-edit-word-keyword-display-files
        let tmpMask = props.displayFormat['mask'];
        let allowNegatif = false;

        var maskChar = /[!@#$%^&*()_+\-=[\]{};:\\|<>/?~]/; // check for all char except ',' and '.' and '0'... if no char is detected then it's not a real mask

        if (tmpMask.replace(/'/g, '').endsWith('-')) {
          allowNegatif = true;
          tmpMask = tmpMask.slice(0, -1);
        }

        if (props.displayFormat['thousandSeparator'] === undefined) {
          if (tmpMask.indexOf(',') >= 0) {
            props.displayFormat['thousandSeparator'] = ',';
          }
        }

        if (props.displayFormat['nbDigits'] === undefined) {
          // No digit but the mask is for a numeric (like money, double, etc.)
          if ((tmpMask.indexOf(',') >= 0) || (tmpMask.indexOf('.') >= 0)) {
            var digitSpaceInMask = (tmpMask.match(/ /g) || []).length;
            props.displayFormat['nbDigits'] = digitSpaceInMask;
          }
        }

        if (props.displayFormat['nbDecimals'] === undefined) {
          // No decimal but the mask is for a numeric (like money, double, etc.)
          const dotPosition = tmpMask.indexOf('.');
          if (dotPosition >= 0) {
            const decimalSubString = tmpMask.substring(dotPosition + 1);
            var decimalSpaceInMask = (decimalSubString.match(/ /g) || []).length;
            props.displayFormat['nbDecimals'] = decimalSpaceInMask;
          }
        }

        if (maskChar.test(tmpMask)) {
          // it's a mask (like date separator, phone number, etc.)
          return tmpMask.replace(/ /g, '#').replace(/&/g, ' ').replace(/'/g, '');
        } else {
          // Not really a mask if nothing else then decimal separator, dot for decimal and leading 0
          return null;
        }
      }
    }

    return null;
  };

  const padLeft = (num: number, size: number): string => {
    if (num) {
      let numString = num.toString();

      while (numString.length < size) {
        numString = "0" + numString;
      }
      return numString;
    }

    return '';
  }

  const getValue = (): any => {
    if (props.displayFormat && (props.displayFormat['padLeft'])) {
      // When padleft is required (the real padleft in the component does not work)
      return padLeft(props.value, props.maxlength ?? 0);
    } else if (props.displayFormat && (props.displayFormat['mask'])) {
      const maskEditableCharCount = (props.displayFormat['mask'].match(/ /g) || []).length;
      var maskChar = /[!@#$%^&*()_+\-=[\]{};:\\|<>/?~]/; // check for all char except ',' and '.' and '0'... if no char is detected then it's not a real mask

      if (maskChar.test(props.displayFormat['mask'])) {
        // if the mask is for a date... it's a guess!
        return padLeft(props.value, maskEditableCharCount);
      }
      // return the value in string
      return (props.value ? props.value.toString() : props.value);
    } else {
      // return the numeric as is
      return props.value;
    }
  }

  const renderInput = (): React.ReactElement => {
    const format = getFormatMaskFromDisplayFormat();
    return (
      <React.Fragment>
        <div className={baseInput.getInputWrapperClasses(props) + ' f-numeric'}>
          {props.label && <label htmlFor={props.name}>{props.label}</label>}
          <div className={baseInput.getResizableContainerClasses(props) + ' resizable-container'}>
            <NumberFormat
              format={format}
              value={getValue()}
              displayType={'input'}
              thousandSeparator={props.displayFormat && props.displayFormat['thousandSeparator']}
              prefix={props.displayFormat && props.displayFormat['prefix']}
              suffix={props.displayFormat && props.displayFormat['suffix']}
              decimalScale={props.displayFormat && props.displayFormat['nbDigits'] && props.displayFormat['nbDecimals']}
              fixedDecimalScale={true}
              allowLeadingZeros={props.displayFormat && props.displayFormat['padLeft']}
              isAllowed={(values: any): any => {
                return maxValue !== -1 ? (values.formattedValue === '' || ((values.floatValue <= maxValue) && (values.value.length <= maxValue.toString().length))) : true
              }}
              customInput={InputText}
              className={'fp-inputtext'}
              id={props.name}
              placeholder={props.placeholder}
              readOnly={props.readonly}
              disabled={props.protect}
              // Max lenght property cause a bug.. but anyway we control the length by the mask or by the number of digits
              // {...(format === null && { maxLength: props.maxlength })}
              tooltip={props.tooltip}
              onValueChange={(values: any): void => {
                const { floatValue, value } = values;

                // Use string value to compare
                if (userkeyDownDetected && value !== props.value) {
                  userkeyDownDetected = false;
                  let numValue = floatValue ?? null; // if undefined then put null for backend
                  setSuffixValue(baseInput.setSuffix(numValue, props.suffix));
                  baseInput.onChange(numValue, props.onValueChange);
                }
              }}
              onFocus={(e: any): void => baseInput.onFocus(e, props.onFocus)}
              onBlur={(e: any): void => baseInput.onBlur(e, props.onBlur)}
              onClick={(e: any): void => { if (props.useCmdkeyClick && !props.readonly) { baseInput.onCmdkeyClick(e, props.cmdkey || '', props.onCmdkeyClick); } }}
              onKeyDown={(e: any): void => {
                userkeyDownDetected = true;
                if (props.onKeyDown) {
                  props.onKeyDown(e);
                }
              }}
              keyfilter={getKeyFilter()}
            />

            {props.suffix !== undefined && <span className={'suffix'}>{suffixValue}</span>}

            {
              props.promptable &&
              !props.protect &&
              (
                <Button
                  className={'promptable-btn'}
                  icon="pi pi-search"
                  onClick={(e: any): void => {
                    e.preventDefault();
                    baseInput.onPrompt(e, props.onPrompt);
                  }}
                />
              )
            }

            {props.required && <span className={'required-symbol'}>*</span>}
          </div>
        </div>
        {props.error && props.error !== '' &&
          <span className={'error-msg'}>{props.error}</span>
        }
      </React.Fragment>
    );
  };

  /**
   * Format appropriate primeNg keyFilter to match decimal and positive attributes
   */
  const getKeyFilter = (): KeyFilterType => {
    const decimalFilter = props.decimal ? 'num' : 'int';
    const positiveFilter = props.positive ? 'p' : '';

    let result: KeyFilterType = (positiveFilter + decimalFilter) as KeyFilterType;

    if (props.displayFormat && props.displayFormat['filter']) {
      // Only number and characters applicable to a number are accepted (123,456.78) (keyboard shift D)
      if (props.displayFormat['filter'].toLowerCase() === 'digit') {
        result = /^[0-9]/;
      }

      // Only number without dot, coma and other characters (keyboard shift Y)
      if (props.displayFormat['filter'].toLowerCase() === 'filterednumeric') {
        result = /^[0-9,.+-\s]/;
      }

      // Only number without dot, coma and other characters (keyboard shift M)
      if (props.displayFormat['filter'].toLowerCase() === 'numericstring') {
        result = /^[0-9,.+-]/;
      }
    }

    return result;
  };

  return renderInput();
};

// Set default props
FNumericInput.defaultProps = {
  decimal: true,
  positive: false,
};

export default FNumericInput;
