import * as React from 'react';
import FInput, { FInputProps } from '../f-input/f-input';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import '../f-input/f-input.css';
import './f-multiline-input.css';

export default function FMultilineInput(props: FInputProps): React.ReactElement {
  const baseInput = new FInput();
  const [suffixValue, setSuffixValue] = React.useState(baseInput.setSuffix(props.value, props.suffix));
  const [maxlength] = React.useState(props.displayFormat && props.displayFormat['textLength']
    ? props.displayFormat['textLength'] : props.maxlength);

  React.useEffect((): any => {
    if (props.autofocus) {
      setTimeout(() => {
        baseInput.focusElement(props.id);
      }, 100);
    }
  }, []);

  const onChange = (val: any): void => {
    if (props.displayFormat && props.displayFormat['textCase'] === 'uppercase') {
      val = String(val).toUpperCase();
    }
    setSuffixValue(baseInput.setSuffix(val, props.suffix));
    baseInput.onChange(val, props.onValueChange);
  };

  const renderInput = (): React.ReactElement => {
    return (
      <React.Fragment>
        <div className={baseInput.getInputWrapperClasses(props) + ' f-long'}>
          {props.label && <label htmlFor={props.name}>{props.label}</label>}
          <div className={baseInput.getResizableContainerClasses(props) + ' resizable-container'}>

            <InputTextarea
              className={props.displayFormat && props.displayFormat['textCase'] === 'uppercase' ? 'fp-inputtext uppercase' : 'fp-inputtext'}
              id={props.name}
              value={props.value}
              placeholder={props.placeholder}
              readOnly={props.readonly}
              disabled={props.protect}
              maxLength={maxlength}
              tooltip={props.tooltip}
              /*             tooltipPosition={props.tooltipPosition === undefined ? 'bottom' : props.tooltipPosition}
                          tooltipStyleClass={props.tooltipStyleClass}
                          tooltipEvent={props.tooltipEvent === undefined ? 'hover' : props.tooltipEvent} */
              onChange={(e: any): void => { onChange(e.target.value); }}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              onClick={(e: any): void => { if (props.useCmdkeyClick && !props.readonly) { baseInput.onCmdkeyClick(e, props.cmdkey || '', props.onCmdkeyClick); } }}
            />

            {props.suffix !== undefined && <span className={'suffix'}>{suffixValue}</span>}

            {
              props.promptable &&
              !props.protect &&
              (
                <Button
                  className={'promptable-btn'}
                  icon="pi pi-search"
                  onClick={(e: any): void => {
                    e.preventDefault();
                    baseInput.onPrompt(e, props.onPrompt);
                  }}
                />
              )
            }

            {props.required && <span className={'required-symbol'}>*</span>}
          </div>
        </div>
        {props.error && props.error !== '' &&
          <span className={'error-msg'}>{props.error}</span>
        }
      </React.Fragment>
    );
  };

  return renderInput();
}
