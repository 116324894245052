import * as React from 'react';
import FInput, { FInputProps } from '../f-input/f-input';

import '../f-input/f-input.css';
import './f-label.css';

export interface FLabelProps extends FInputProps {
    text: string;
}

export default function FLabel(props: FLabelProps): React.ReactElement {
    const baseInput = new FInput();

    return (
        <div className={baseInput.getInputWrapperClasses(props)}>
            <div className={baseInput.getResizableContainerClasses(props) + ' resizable-container-label'}>
                <label className="orphan-label">{props.text}</label>
            </div>
        </div>
    );
}
