import { getInputWrapperClasses } from '@fresche/common-lib';

export interface FInputProps {
  /**
   * name attribute of the input
   */
  name?: string;
  /**
   * unique identifier of the input
   */
  id: string;
  /**
   * value attribute of the input
   */
  value?: any;
  /**
   * maxlength attribute of the input
   */
  maxlength?: number;
  /**
   * label attribute of the input
   */
  label?: string;
  /**
   * if label if always visible
   */
  alwaysVisibleLabel?: boolean;
  /**
   * label position
   */
  labelOnTop?: boolean;
  /**
   * placeholder attribute of the input
   */
  placeholder?: string;
  /*
   * additional user classname
  */
  className?: string;
  /**
   * suffix attribute of the input
   * @example
   * the value can be either a string, or an object like this:
   * {'key1': 'value1', 'key2': 'value2'};
   * an object will allow contextual value of suffix based on input value.
   */
  suffix?: any;
  /**
   * size attribute of the editable part (size of the text if it's a label)
   */
  size?: number;
  /**
   * invalid attribute of the input. error message will appear based on this value
   */
  invalid?: boolean;
  /**
   * promptable attribute of the input. popup will apear on click of a button if true.
   */
  promptable?: boolean;
  /**
   * required attribute of the input. * will apear on the right if true.
   */
  required?: boolean;
  /**
   * error message
   */
  error?: string;
  /**
   * focuses this input automatically
   */
  autofocus?: boolean;
  /**
   * readonly attribute of the input
   */
  readonly?: boolean;
  /**
   * tooltip attribute of the input
   */
  tooltip?: string;
  /**
   * position of the tooltip, valid values are right, left, top and bottom
   */
  tooltipPosition?: string;
  /**
   * style class of the tooltip
   */
  tooltipStyleClass?: string;
  /**
   * event to show the tooltip, valid values are hover and focus.
   */
  tooltipEvent?: string;
  /**
   * if useCmdkeyClick is true the we will trigger onCmdkeyClick with cmdkey as parameter
   */
  cmdkey?: string;
  useCmdkeyClick?: boolean;
  onCmdkeyClick?: (e: any, cmdkey: string) => any;
  /**
   * valuechange Event Emitter for 2 way binding
   */
  onValueChange?: (newValue: any) => any;
  /**
   * onKeyDown Event Emitter for 2 way binding
   */
  onKeyDown?: (e: Event) => any;
  /**
   * onPrompt Event Emitter for 2 way binding
   */
  onPrompt?: (e: any) => any;
  /**
   * onFocus Event Emitter for 2 way binding
   */
  onFocus?: (e: any) => any;
  /**
   * onBlur Event Emitter for 2 way binding
   */
  onBlur?: (e: any) => any;

  /**
   * display format attributes
   */
  displayFormat?: object;

  /**
   * Full set of conditions
   */
  conditions?: any;

  /*
   * display conditions
  */
  color?: string;
  blinking?: boolean;
  highintensity?: boolean;
  reverseimage?: boolean;
  underline?: boolean;
  protect?: boolean;
  hidden?: boolean;
}

export default class FInput {
  public getInputWrapperClasses = getInputWrapperClasses;

  /**
   * Add size class on component
   */
  getResizableContainerClasses(props: any): string {
    const classes: any[] = [];

    if (props.size && props.size <= 30) {
      classes.push('size-' + props.size);
    } else {
      // By default, the field take all space.  Or if the size is greater then 30 char
      classes.push('size-max');
    }

    return classes.join(' ');
  }

  /**
   * Focuses the HTML input element
   */
  focusElement(id: string): void {
    const el = document.getElementById(id);
    if (el) {
      el.focus();
    }
  }

  /**
   * Focuses the HTML input element
   */
  setSuffix(value: string, suffix?: any): any {
    // If input has a suffix that changes with the value of its content
    if (suffix) {
      if (typeof suffix === 'object') {
        return suffix[value.toString()];
      } else if (typeof suffix === 'string') {
        return suffix;
      }
    }
    return '';
  }

  /**
   * Component onchange event
   * @param newValue the new value of the component
   * @param onValueChange function to call on value change
   */
  onChange(newValue: string, onValueChange: any): void {
    if (onValueChange && typeof onValueChange === 'function') {
      onValueChange(newValue);
    }
  }

  /**
   * Component onFocus event
   * @param e onFocus event element
   * @param onFocus function to call on focus
   */
  onFocus(e: any, onFocus: any): void {
    // Select the text in the input if available
    if (e && e.target && typeof e.target.setSelectionRange === 'function') {
      e.target.setSelectionRange(0, e.target.value.length);
    }
    // Emit the focus event
    if (onFocus !== undefined && typeof onFocus === 'function') {
      onFocus(e);
    }
  }

  /**
   * Component onLur event
   * @param e onBlur event element
   * @param onBlur function to call on blur
   */
  onBlur(e: Event, onBlur: any): void {
    // Emit the focus event
    if (onBlur !== undefined && typeof onBlur === 'function') {
      onBlur(e);
    }
  }

  /**
   * Component onPrompt event
   * @param e onPrompt event element
   * @param onPrompt function to call on prompt
   */
  onPrompt(e: Event, onPrompt: any): void {
    if (onPrompt !== undefined && typeof onPrompt === 'function') {
      onPrompt(e);
    }
  }

  /**
   * Special OnClick when useCmdKeyClick is true
   * @param e 
   * @param cmdkey 
   * @param onCmdkeyClick 
   */
  onCmdkeyClick(e: Event, cmdkey: string, onCmdkeyClick: any): void {
    if (onCmdkeyClick !== undefined && typeof onCmdkeyClick === 'function') {
      onCmdkeyClick(e, cmdkey);
    }
  }
}
