import * as React from 'react';
import FInput, { FInputProps } from '../f-input/f-input';
import { Button } from 'primereact/button';
import '../f-input/f-input.css';
import '../f-custom-input/f-custom-input.css';

/*
  Custom component should be declared like this in your code

  // Render your component
  //  field: the name of the field associated with the column and your component.
  //  component: your function declared to render your component.
  customCellComponent(props: any, onChangeEvent: any): React.ReactElement {
    // Your code
    return (
      <YourComponent>

      </YourComponent>
    );
  }
*/

export interface FCustomInputProps extends FInputProps {
  custom?: Function;
}

export default function FCustomInput(props: FCustomInputProps): React.ReactElement {
  const baseInput = new FInput();
  const [suffixValue, setSuffixValue] = React.useState(baseInput.setSuffix(props.value, props.suffix));

  React.useEffect((): any => {
    if (props.autofocus) {
      setTimeout(() => {
        baseInput.focusElement(props.id);
      }, 100);
    }
  }, []);

  const onChange = (val: any): void => {
    baseInput.onChange(val, props.onValueChange);
  };

  const renderInput = (): React.ReactElement => {
    // Necessary because of bug prime react that cannot accept null nask
    return (
      <React.Fragment>
        <div className={baseInput.getInputWrapperClasses(props) + ' custom-input'}>
          {props.label && <label htmlFor={props.name}>{props.label}</label>}
          <div className={baseInput.getResizableContainerClasses(props) + ' resizable-container'}>
            {props.custom &&
              (
                props.custom(props, onChange)
              )
            }

            {props.suffix !== undefined && <span className={'suffix'}>{suffixValue}</span>}

            {
              props.promptable &&
              !props.protect &&
              (
                <Button
                  id={props.name + '-promptable'}
                  className={'promptable-btn'}
                  icon="pi pi-search"
                  onClick={(e: any): void => {
                    e.preventDefault();
                    baseInput.onPrompt(e, props.onPrompt);
                  }}
                />
              )
            }
            {props.required && <span className={'required-symbol'}>*</span>}
          </div>
        </div>
        {props.error && props.error !== '' &&
          <span className={'error-msg'}>{props.error}</span>
        }
      </React.Fragment>
    );
  };

  return renderInput();
}
