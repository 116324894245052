import * as React from 'react';
import { Button, ButtonPositionType } from 'primereact/button';
import './f-continue-button.css';

export interface FContinueButtonProps {
  id: string;
  title: string;
  icon?: string;
  iconPos?: ButtonPositionType;
  tooltip?: string;
  tooltipOptions?: object;
  disabled: boolean;
  imageAltText?: string;
  image?: string;
  imagePos?: string;
  handleContinue: () => any;
}

export default function FContinueButton(props: FContinueButtonProps): React.ReactElement {
  React.useEffect((): any => {
    document.addEventListener('keydown', handleContinue, false);
    return function cleanup(): void {
      document.removeEventListener('keydown', handleContinue, false);
    };
  });

  const handleContinue = (e: any): void => {
    if ((e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter')) && !props.disabled) {
      e.preventDefault();
      props.handleContinue();
    }
  };

  const renderImage = (): React.ReactElement => {
    if (props.image) {
      return (
        <React.Fragment>
          <img alt={props.imageAltText ? props.imageAltText : ''} src={props.image} className={`custom-image${props.imagePos ? ' ' + props.imagePos : ''}`} />
        </React.Fragment>
      );
    }

    return <></>;
  };

  return (
    <Button
      id={props.id}
      className="continue-button"
      label={props.title}
      disabled={props.disabled}
      tooltip={props.tooltip}
      tooltipOptions={props.tooltipOptions ? props.tooltipOptions : { position: 'top' }}
      icon={props.icon}
      iconPos={props.iconPos}
      onClick={handleContinue}
    >
      {renderImage()}
    </Button>
  );
}
