import * as React from 'react';

// Fresche
import FGridCell from '../f-grid-cell/f-grid-cell';
import FActionButton from '../../buttons/f-action-button/f-action-button';
import FDateTimeInput from '../../layout/f-date-time-input/f-date-time-input';
import FTextInput from '../../layout/f-text-input/f-text-input';
import FSelectInput from '../../layout/f-select-input/f-select-input';

// Prine React
import { DataTable, DataTableSortOrderType } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { MultiSelect } from 'primereact/multiselect';

// Style
import './f-grid.css';

/**
 *
 */
export interface FGridProps {
  /**
   * model containing all the grid values
   */
  model: any;

  /**
   * Screen Definition
   */
  screenDef: any;

  /**
   * Custom components
   * An array of object with two properties:
   *    field: the name of the field associated with the column.
   *    component: your function declared to display your component
   * Sample:
   *    {[{ field: 'stringColumn', component: this.customCellComponentDemo }, { field: 'numberColumn', component: this.customCellComponent }]}
   */
  customComponents?: any;

  /**
   * Errors associated to filter
   */
  filterErrors?: any;

  /**
   *
   */
  selectionMode?: string;

  /**
   *
   */
  conditions?: any;

  dateFormat?: string;
  timeSeparator?: string;
  showSeconds?: boolean;

  /**
   * If the action buttons are showed as a menu or not
   */
  actionsAsMenu?: boolean;
  actionMenuIcon?: string;
  actionMenuPos?: string;

  /**
   * triggered when a part of the model has changed
   */
  onModelChange: (model: object) => any;

  /**
   * triggered when grid pages changes
   */
  onPageChange?: (e?: any) => any;

  /**
   * triggered when a field is focused
   */
  onTableFieldFocus?: (fieldName: string, shortFieldName: string, rowNumber: number) => void;

  /*
   * triggered when user selection a radio button or check box.  RowData is the current row selected
  */
  onSelectionChange?: (rowData: any) => void;

  onTableCmdkeyClick?: (fieldName: string, shortFieldName: string, cmdkey: string, rowNumber: number) => void;

  /**
   * triggered when user click on action button
   */
  onActionButton?: (command: string) => any;

  /**
   * OBSOLETE.  Always true
   */
  autoFocus?: boolean;
}

/**
 *
 */
export default class FGrid extends React.Component<FGridProps> {
  // Class references
  public tableElement: any;
  public table = React.createRef();
  public dt: DataTable;
  public actionMenu: any;
  public actionMenuMap = new Map<number, React.RefObject<any>>();

  public autofocus: boolean;

  protected readonly dateFormat: string;
  protected readonly timeSeparator: string;
  protected readonly showSeconds: boolean;

  public gridLength: number;
  public hasFilters: boolean; // if the grid has any filters
  public selectionMode: string;
  public isMenuOpen: boolean;

  protected originalData: any;
  protected totalRecords: number;
  protected numRows: number; // numrows attribute of the grid define number of row displayed per page
  protected disabledSelections: object[]; // has indexes of records with disabled selections
  public firstRecordIndex: number; // id of the first page used by primeng table
  public firstRecordIndexOnPageChange: number; // id of the first page used by primeng table, occured onPageChange AND only when the data is fully loaded
  protected currentPageNumber: number; // current page number position start at 0

  protected gridDefinition: any;
  protected model: any;
  protected selectedRows: any;

  protected readonly ellipsisMenuItems: any;
  protected ellipsisMenu: any;

  protected allAvailableColumns: any[];
  protected visibleColumns: any[];

  /**
   *
   */
  constructor(props: FGridProps) {
    super(props);

    // to have access to "this"
    this.cellBodyTemplate = this.cellBodyTemplate.bind(this);
    this.actionMenuBodyTemplate = this.actionMenuBodyTemplate.bind(this);
    this.getCellFilter = this.getCellFilter.bind(this);
    this.getPage = this.getPage.bind(this);
    this.getPageAllData = this.getPageAllData.bind(this);
    this.movePage = this.movePage.bind(this);
    this.exportToCSV = this.exportToCSV.bind(this);
    this.gridExport = this.gridExport.bind(this);
    this.gridSort = this.gridSort.bind(this);
    this.columnSort = this.columnSort.bind(this);
    this.resetColumnOrder = this.resetColumnOrder.bind(this);
    this.onColReorder = this.onColReorder.bind(this);

    this.model = this.props.model;

    // Make a value copy of the original model
    this.originalData = JSON.parse(JSON.stringify(this.props.model));

    this.disabledSelections = [];
    this.hasFilters = false;

    this.dateFormat = this.props.dateFormat ? this.props.dateFormat : 'yy-mm-dd';
    this.timeSeparator = this.props.timeSeparator ? this.props.timeSeparator : ':';
    this.showSeconds = this.props.showSeconds ? this.props.showSeconds : true;

    this.autofocus = true;
    this.gridDefinition = this.props.screenDef.gridDefinition;

    // Options Menu
    this.ellipsisMenuItems = [
      {
        label: 'Options',
        items: [
          {
            label: 'Reset',
            icon: 'pi pi-undo',
            command: () => {
              this.resetColumnOrder()
            }
          }
        ]
      },
    ];

    this.initialize();
  }

  /**
   *
   */
  componentDidUpdate(): void {
    let needRefreshState = false;

    if (JSON.stringify(this.gridDefinition) !== JSON.stringify(this.props.screenDef.gridDefinition)) {
      needRefreshState = true;
    }

    // Because we have a dynamic nested object, we compare each node valud
    if (JSON.stringify(this.model.fields) !== JSON.stringify(this.props.model.fields)) {
      needRefreshState = true;
    }

    if (this.model.subfile && this.props.model.subfile) {
      for (let index = 0; index < this.props.model.subfile.length; index++) {
        const propsFields = JSON.stringify(this.props.model.subfile[index]);
        const stateFields = JSON.stringify(this.model.subfile[index]);

        if (propsFields !== stateFields) {
          needRefreshState = true;
          break;
        }
      }

      if (this.model.subfile.length !== this.props.model.subfile.length) {
        needRefreshState = true;
      }
    }

    if (this.model.subfileHasAllData !== this.props.model.subfileHasAllData) {
      needRefreshState = true;
    }

    if (this.props.model.subfileHasAllData) {
      if (this.model.subfileTopRRN !== this.props.model.subfileTopRRN) {
        needRefreshState = true;
      }
    }

    if (this.model.subfilePageNumber !== this.props.model.subfilePageNumber) {
      needRefreshState = true;
    }

    if (needRefreshState) {
      this.gridDefinition = this.props.screenDef.gridDefinition;
      this.model = this.props.model;
      this.initialize();
      this.forceUpdate();
    }
  }

  /**
   * Initialize the compoment
   */
  initialize(): void {
    // Hide all potential menu already opened and clear the list (each row has its own menu)
    this.actionMenuMap.forEach((menu: any) => {
      (menu as React.RefObject<any>).current.hide(menu);
      menu = null;
    });
    this.actionMenuMap.clear();

    // Load columns
    this.allAvailableColumns = this.getColumnsUserConfig().map((col: any[]): any => {
      return { field: col[0].field }
    });

    // Get all columns to put in array for Prime Control
    const userVisibleColumns = localStorage.getItem(`grid-visible-columns-${this.props.screenDef.package}-${this.props.screenDef.deviceName}`);

    // By default all columns are considered visible
    this.visibleColumns = this.allAvailableColumns;
    if (userVisibleColumns !== null) {
      // if user have selected some columns
      this.visibleColumns = JSON.parse(userVisibleColumns);
    }

    this.checkForDisabledSelections();
    this.setupGrid();
    this.generateTableData();
  }

  /**
   * generate table data
   */
  generateTableData(): void {
    if (this.model !== undefined) {
      if (this.model['subfile'] !== null && this.model['subfile'] !== undefined) {
        this.selectedRows = [];
        for (const record of this.model['subfile']) {
          if (record['recordSelected'] === 'Y') {
            this.selectedRows.push(record);
          }
        }

        // Copy by JSON strignify to make sure all object are copy
        this.originalData = JSON.parse(JSON.stringify(this.model));

        if (this.model.subfileHasAllData) {
          this.totalRecords = this.model['subfile'].length;
        } else {
          // Assign totalRecords if isLastPage == true to total till now and defaults at 10000
          this.totalRecords = this.model['isLastPage'] ? (this.model['subfilePageNumber'] * this.model['subfilePageSize']) + this.model['subfile'].length : 10000;
        }

        if (this.model['subfileTotalRowCount']) {
          this.totalRecords = this.model['subfileTotalRowCount'];
        }

        // Assign rowSize from the model
        if (this.model['subfileHasAllData']) {
          this.numRows = 10; // override with default value 10
        } else {
          this.numRows = this.model['subfilePageSize'];
        }

        let currentItemNumber = 0;
        if (this.model['subfile'] && this.model['subfile'].length > 0) {
          currentItemNumber = Math.floor(this.model['subfilePageNumber'] * this.model['subfilePageSize']);
        } else {
          this.totalRecords = 0;
        }
        this.currentPageNumber = this.model['subfilePageNumber'];

        // If we have all the data
        if (this.model['subfileHasAllData']) {
          // If a RRN meta data is passed by the backend
          if (this.model['subfileTopRRN']) {
            // Found the index in subfile array corresponding to the rrn
            const rrnRecordIndex = this.model['subfile'].map((obj: any) => {
              return obj.rrn;
            }).indexOf(this.model['subfileTopRRN']);
            this.firstRecordIndex = (rrnRecordIndex > -1 ? rrnRecordIndex : 0);

            if (this.firstRecordIndexOnPageChange !== undefined) {
              this.firstRecordIndex = this.firstRecordIndexOnPageChange;
            } else {
              this.firstRecordIndex = (rrnRecordIndex > -1 ? rrnRecordIndex : 0);
            }
          } else {
            if (this.firstRecordIndexOnPageChange !== undefined) {
              this.firstRecordIndex = this.firstRecordIndexOnPageChange;
            } else {
              this.firstRecordIndex = currentItemNumber;
            }
          }
        } else {
          this.firstRecordIndex = currentItemNumber;
        }
      }
    }
  }


  /**
   * Checks the grid records for any disabled selections
   */
  checkForDisabledSelections(): void {
    this.disabledSelections = [];
    if (this.model['subfile']) {
      this.model['subfile'].forEach((record: object) => {
        if (record['fieldConditions'] && record['fieldConditions'].length > 0) {
          record['fieldConditions'].forEach((condition: object) => {
            if (condition['RCD_selected']) {
              const disabledRecordIndex: any = this.model['subfile'].indexOf(record)
                + Math.floor(this.model['subfilePageNumber'] * this.model['subfilePageSize']);

              this.disabledSelections.push(disabledRecordIndex);
            }
          });
        }
      });
    }
  }

  /**
   * Set some properties
   */
  setupGrid(): void {
    this.hasFilters = false;

    if (this.gridDefinition) {
      if (this.getColumnsUserConfig()) {
        let totalLength = 0;

        this.getColumnsUserConfig().forEach((column: any) => {
          if (!this.hiddenColumn(column[0].field)) {
            if (column[0].size) {
              totalLength += column[0].size;
            }
          }

          column.forEach((cell: any) => {
            // If cell has filters
            if (cell.filters && cell.filters.length && !this.hiddenColumn(column[0].field)) {
              let cellHiddenFilters = 0;
              cell.filters.forEach((filter: any) => {
                if (this.hiddenFilter(filter)) {
                  cellHiddenFilters++;
                }
              });
              if (cellHiddenFilters < cell.filters.length) {
                this.hasFilters = true;
              }
            }

            // If cell has options
            if (cell.options && cell.options.length) {
              // Loop every cell
              cell.options.forEach((option: any, index: number) => {
                // Translate every label
                cell.options[index].label = option.label;
              });
            }
          });
        });

        this.gridLength = totalLength;
      }

      this.selectionMode = this.props.selectionMode ? this.props.selectionMode : this.gridDefinition.selectionMode;
    }
  }

  /**
   * Return menu item for the ActionMenuAsButton
   */
  getActionMenuItems(): any[] {
    let actionItems: any[] = [];

    // Transform grid definition actions to fit Prime's Menu items
    if (this.gridDefinition.actions) {
      this.gridDefinition.actions.forEach((item: any, index: number): void => {
        // Make a copy of action from grid definition
        const clonedItem = JSON.parse(JSON.stringify(item));
        // Keep a trace of the action's command value
        const action = this.gridDefinition.actions[index];
        // Set the command attribute as a function (called when Prime item menu clicked)
        clonedItem.command = (): void => {
          this.onActionButtonClick(action);
        };
        clonedItem.icon = this.getMenuIcon(clonedItem);
        // Add item to the list of items that's used by the Prime menu
        actionItems.push(clonedItem);
      });

      // If multiple selection then we add a label to indicate how many items will be affected by the action
      if (this.selectedRows && this.selectedRows.length > 1) {
        actionItems = [{
          // @ts-ignore
          label: `Bulk Edit (${this.selectedRows.length} items)`,
          items: actionItems
        }]
      }
    }

    return actionItems;
  }

  /**
   * Override if you want new icon based on other logic.  The icon should be the name of the icon
   */
  getMenuIcon(gridAction: any): any {
    // By default return the icon declared in the screen def.  This function can be overrided by inherited grid to provided other icon
    return gridAction.icon;
  }

  // ====================================
  //  GRID PAGINATION
  // ====================================

  /**
   * compare next page and current to determine paginaiton direction then set command to model...
   */
  getPage(event: any): void {
    const nextPage = (event.first / event.rows);
    if (this.currentPageNumber > nextPage && this.model['subfile'].length > 0) {
      this.movePage('previous', event);
    } else if (this.currentPageNumber < nextPage && this.model['subfile'].length > 0) {
      this.movePage('next', event);
    }
  }

  /**
   * When grid has all data loaded, pagination must be handled here
   */
  getPageAllData(event: any) {
    this.firstRecordIndexOnPageChange = event.first;
    this.generateTableData();
    this.forceUpdate();
  }

  /**
   * Return the top RRN number of the first record (call externally by the frontend)
   */
  getTopRRN(): number {
    const firstRecordWithRRN = (this.firstRecordIndexOnPageChange ? this.firstRecordIndexOnPageChange : this.firstRecordIndex);

    if (this.model['subfileHasAllData'] && this.model['subfile'].length > firstRecordWithRRN) {
      return this.model['subfile'][firstRecordWithRRN].rrn;
    }
    return -1;
  }

  /**
   * When user click on page next or previous
   */
  movePage(direction: string, event?: any): void {
    if (((direction === 'next' && !this.model['isLastPage']) ||
      (direction === 'previous' && this.currentPageNumber !== 0))) {
      const onPageChangeEvent = {
        pageOfPages: this.props.screenDef.gridDefinition.pageOfPages || false,
        direction: direction,
        pageNumber: (event ? event.page : this.currentPageNumber + (direction === 'next' ? 1 : -1)),
        pageSize: this.model['subfilePageSize']
      };

      if (this.props.onPageChange) {
        this.props.onPageChange(onPageChangeEvent);
      }
    }
  }

  // ====================================
  //  DATA HANDLING FUNCTIONS
  // ====================================

  /**
   * Checks in the conditions object if a column should be hidden.
   * @param fieldName Name of the column to check for
   */
  hiddenColumn(fieldName: string): boolean {
    let isHiddenColumn = false;

    if (this.props.conditions && this.props.conditions.grid && this.props.conditions.grid[fieldName]) {
      const conditions = this.props.conditions.grid[fieldName];

      isHiddenColumn = conditions.hidden !== undefined ? conditions.hidden : false;

      if (isHiddenColumn === false && conditions.rows) {
        // If not hidden by its initialDisplay then we look inside each "rows" for a hidden property
        for (let i = 0; ; i++) {
          if (conditions.rows[i] === undefined) {
            break;
          }

          if (conditions.rows[i].hidden !== undefined) {
            isHiddenColumn = conditions.rows[i].hidden;
            if (conditions.rows[i].hidden === false) {
              isHiddenColumn = false;
              break; // Exit for loop and not hide the column
            }
          }
        }
      }
    }

    // If not hidden by condition then validate if the user want to show it
    if (isHiddenColumn === false) {
      // Validate if user want this column hidden or not
      const userVisibleColumns = localStorage.getItem(`grid-visible-columns-${this.props.screenDef.package}-${this.props.screenDef.deviceName}`);

      if (userVisibleColumns !== null) {
        let isHiddenColumnByUser = true;
        const visibleObj = JSON.parse(userVisibleColumns);

        visibleObj.forEach((element: any) => {
          if (element.field === fieldName) {
            isHiddenColumnByUser = false;
          }
        });

        return isHiddenColumnByUser;
      }
    }

    return isHiddenColumn;
  }

  /**
   *
   */
  getColumnWidth(col: any): any {
    if (col && this.gridLength) {
      const lengthValue = (col.size !== undefined) ? col.size : (col.displayFormat && col.displayFormat.textLength ? col.displayFormat.textLength : (col.textLength ? col.textLength : null));
      // return { minWidth: `${((lengthValue / this.gridLength) * 100)}%` };
      return { minWidth: `${lengthValue}rem` };
    }
  }

  /**
   * Determine if a column can be sortable or not.   Return true to allow sort on the column.
   */
  getSortable(col: any): boolean {
    if (col) {
      // if the subfile has all the data and the column is sortable
      if ((this.model.subfileHasAllData === true || this.gridDefinition.exportable) && col.sortable !== false) {
        return true;
      }
    }

    return false;
  }

  /**
   * Determine if a column is exportable
   */
  getExportable(col: any): boolean {
    if (col) {
      // if the subfile has all the data or grid is exportable and the column is exportable
      if ((this.model.subfileHasAllData === true || this.gridDefinition.exportable) && col.exportable !== false) {
        return true;
      }
    }

    return false;
  }

  /**
   * Checks in the conditions object if a filter should be hidden.
   * @param filter the filter object
   */
  hiddenFilter(filter: any): boolean {
    if (this.props.conditions && this.props.conditions.grid && this.props.conditions.grid.filters) {
      const cellConditions = this.props.conditions.grid.filters[filter.field];
      if (cellConditions) {
        return cellConditions.hidden !== undefined ? cellConditions.hidden : false;
      }
    }
    return false;
  }

  /**
   * Checks in the conditions object if a action should be hidden.
   * @param action the axction object
   */
  hiddenActionButton(action: any): boolean {
    if (this.props.conditions && this.props.conditions.grid && this.props.conditions.grid.actions) {
      const actionConditions = this.props.conditions.grid.actions[action.key];
      if (actionConditions) {
        return actionConditions.hidden !== undefined ? actionConditions.hidden : false;
      }
    }

    return false;
  }

  /**
   *
   */
  onSelectionChange(selection: any): void {
    if (selection.type && selection.type === 'row') {
      return; // Do nothing
    }

    if (selection.type && selection.type === 'checkbox') {
      // The latest element in selection.value ( value is an array ) is the last selectioned element
      if (this.props.onSelectionChange) {
        this.props.onSelectionChange(selection.value[selection.value.length - 1]);
      }
    }

    if (selection.type && selection.type === 'radio') {
      // The latest element in selection.value ( value is an object ) is the selectioned element
      if (this.props.onSelectionChange) {
        this.props.onSelectionChange(selection.value);
      }
    }

    this.selectedRows = [];

    const singleSelectionMode = !Array.isArray(selection.value);

    // unselect the previous selection
    this.model['subfile'].forEach((element: any): any => {
      element['recordSelected'] = 'N';
      element['recordDataChanged'] = 'N';
    });

    // Because PrimeReact declare selection has an array if multiple selection is set
    if (singleSelectionMode) {
      if (selection.value) {
        this.selectedRows = selection.value;
        // Because original values don't have these field (optional in the backend) then we just put to default for the comparaison below
        this.selectedRows['recordSelected'] = 'N';
        this.selectedRows['recordDataChanged'] = 'N';

        // PrimeReact single selection is not an array
        const selectedRowElement = JSON.stringify(this.selectedRows);
        this.model['subfile'].forEach((subData: any): any => {
          if (selectedRowElement === JSON.stringify(subData)) {
            // Set selectedRows item as selected
            this.selectedRows['recordSelected'] = 'Y';
            this.selectedRows['recordDataChanged'] = 'Y';

            // Set subfile item as selected
            subData['recordSelected'] = 'Y';
            subData['recordDataChanged'] = 'Y';
          }
        });
      }
    } else {
      // Replace selection inside the selectedRows with 'N' to comparaison below
      for (const record of selection.value) {
        record['recordSelected'] = 'N';
        record['recordDataChanged'] = 'N';
        this.selectedRows.push(record);
      }

      // select the new selection in the model
      this.selectedRows.forEach((element: any): any => {
        this.model['subfile'].forEach((subData: any): any => {
          if (JSON.stringify(element) === JSON.stringify(subData)) {
            // Set selectedRows item as selected
            element['recordSelected'] = 'Y';
            element['recordDataChanged'] = 'Y';

            // Set subfile item as selected
            subData['recordSelected'] = 'Y';
            subData['recordDataChanged'] = 'Y';
          }
        });
      });
    }

    this.props.onModelChange(this.model);
  }

  /**
   * event when actionOnRowSelect is true in the grid.  Occur only when SelectionMode is single.
   */
  onRowSelect(): void {
    if (this.gridDefinition.actionOnRowSelect && this.gridDefinition.actionOnRowSelect.length >= 0) {
      // If actionOnRowSelect is present and the value is not empty
      const actionPosition = this.gridDefinition.actions.map((action: any, _index: any): any => { return action.key }).indexOf(this.gridDefinition.actionOnRowSelect);
      const actionOnRowSelect = this.gridDefinition.actions[actionPosition];
      this.onActionButtonClick(actionOnRowSelect);
    }
  }

  /**
   *
   */
  onFilterValueChange(newValue: any, field: string): any {
    this.model['fields'][field] = newValue;
    this.props.onModelChange(this.model);
  }

  /**
   * manage a change of value in a grid cell
   */
  onCellValueChange(newValue: any, field: string, rowData: any, rowIndex: number): any {
    this.model['subfile'][rowIndex]['fields'][field] = newValue;

    if (this.selectionMode === 'single') {
      this.onSelectionChange({ value: rowData });
    } else {
      const isOriginalValue = this.originalData['subfile'][rowIndex]['fields'][field] === newValue;

      let selectionIndex = -1;
      let selectionsCopy: any[] = [];

      if (this.selectedRows) {
        selectionIndex = this.selectedRows.indexOf(rowData);
        selectionsCopy = this.selectedRows.slice();
      }

      if (selectionIndex === -1) {
        selectionsCopy.push(rowData);
        this.onSelectionChange({ value: selectionsCopy });
      } else {
        if (isOriginalValue) {
          selectionsCopy.splice(selectionIndex ? selectionIndex : 0, 1);
          this.onSelectionChange({ value: selectionsCopy });
        } else {
          this.model['subfile'][rowIndex]['recordSelected'] = 'Y';
          this.model['subfile'][rowIndex]['recordDataChanged'] = 'Y';
          this.props.onModelChange(this.model);
        }
      }
    }
  }

  /**
   * Navigates through the grid based on keydown events.
   * @param event keydown event
   */
  gridNavigation(event: any): void {
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
      event.preventDefault();
      // Get the focused cell
      const focusedElement = document.activeElement;
      // Get the cells in the same column
      const sameColCells = this.getColumnCells();

      if (sameColCells.length) {
        let nextCellIndex = -1;
        sameColCells.forEach((cell: any, cellIndex: number): any => {
          const element = cell.htmlElement.element.nativeElement;
          if (element === focusedElement) {
            nextCellIndex = (event.code === 'ArrowDown') ? cellIndex + 1 : cellIndex - 1;
          }
        });
        if (
          (event.code === 'ArrowUp' && nextCellIndex > -1 && nextCellIndex >= 0) ||
          (event.code === 'ArrowDown' && nextCellIndex > -1 && nextCellIndex < sameColCells.length)
        ) {
          const elementToFocus = sameColCells[nextCellIndex].htmlElement.element.nativeElement;
          elementToFocus.focus();
        }
      }
    }
  }

  /**
   * Returns all the focusable cells that are in the same column as
   * the current focused cell.
   */
  getColumnCells(): any[] {
    /*
    // Get the focused cell
    const focusedElement = document.activeElement;
    // Get the index of the column containing the focused cell
    const columnIndex = focusedElement.closest('td').cellIndex;
    if (focusedElement && columnIndex && this.gridCells) {
      // Get all the cells of the grid
      const sameColCells = this.gridCells.toArray().filter((cell: any) => {
        // Skip dropdowns
        if (!(cell.htmlElement instanceof FSelectInputComponent)) {
          const input = cell.htmlElement;
          // Skip cells that are protected or in readonly mode
          if (!input.protect && !input.readonly) {
            const element = input.element.nativeElement;
            return element && element.closest('td').cellIndex === columnIndex;
          }
        }
      });
      return sameColCells;
    }*/
    return [];
  }

  /**
   * Return true if type is numeric or date
   */
  isRightAlign(type: string): boolean {
    const rightAlignTypes = ['number', 'pnumber', 'pinteger', 'integer', 'currency', 'datetime', 'date', 'time'];
    return rightAlignTypes.indexOf(type) !== -1;
  }

  /**
   * Checks if the selector for a given row is hidden.
   * @param rowIndex index of the grid row.
   */
  // TODO: Bind this code in the html to hide selector
  hiddenSelector(rowIndex: any): boolean {
    let hidden = false;
    if (this.disabledSelections.indexOf(rowIndex) > -1) {
      hidden = true;
    }

    const selectionConditions = this.props.conditions.grid.gridSelection;
    if (selectionConditions) {
      if (selectionConditions.hidden || selectionConditions.protect || selectionConditions.readonly) {
        hidden = true;
      }
      if (selectionConditions.rows && selectionConditions.rows[rowIndex]) {
        const specificCondition = selectionConditions.rows[rowIndex];
        if (specificCondition.hidden || specificCondition.protect || specificCondition.readonly) {
          hidden = true;
        }
      }
    }
    return hidden;
  }

  /**
   * Returns a columns initial display as an object of conditions
   * usable by FGridCell
   */
  getCellConditions(col: any, rowIndex: number): any {
    const field = col.field;
    if (this.props.conditions && this.props.conditions.grid && this.props.conditions.grid[field]) {
      const conditions = this.props.conditions.grid[field];
      if (conditions.rows && conditions.rows[rowIndex] && Object.keys(conditions.rows[rowIndex.toString()]).length) {
        return conditions.rows[rowIndex];
      } else {
        return conditions;
      }
    }
    return {};
  }

  /**
   * Called when an action button is clicked.
   */
  onActionButtonClick(action: string): void {
    if (this.props.onActionButton) {
      this.props.onActionButton(action);
    }
  }

  /**
   * Return a string of all classes in the addClass array property
   */
  getAddClass(classes: string[]): string {
    let result = '';

    if (classes) {
      result = classes.join(' ');
    }

    return result;
  }

  /**
   *
   */
  getFieldErrorMessage(errorMsgs: any, fieldName: string): string {
    let errorMessages = '';
    if (errorMsgs) {
      errorMsgs.forEach((message: any) => {
        if (message['fieldsName'].indexOf(fieldName) !== -1) {
          errorMessages = message['messageText'];
        }
      });
    }
    return errorMessages;
  }

  /**
   * Called when a row is clicked. Adds/removes row from the selection.
   * @param ev click event
   * @param rowIndex index of the clicked row
   */
  selectRow(ev: any, rowIndex: any): void {
    // Get selection mode
    const mode = this.selectionMode;
    if (mode !== 'none' && ev && ev.target && ev.target.classList !== undefined) {
      const classes = ev.target.classList;

      // Toggle only when we are not on an input.  Because on input we are waiting to user action
      if (!classes.contains('p-inputtext') && !classes.contains('p-dropdown') && !classes.contains('p-dropdown-trigger') && !classes.contains('p-dropdown-label') && !classes.contains('p-dropdown-trigger-icon')) {
        this.toggleFromSelection(rowIndex);
      }

      if (classes.contains('p-inputtext') && (ev.target.parentNode && ev.target.parentNode.parentNode && ev.target.parentNode.parentNode.classList.contains('underline'))) {
        this.toggleFromSelection(rowIndex);

        // Only on single selection
        if (this.selectionMode === 'single') {
          this.onRowSelect();
        }
      }
    }
  }

  /**
   * Return row by index
   */
  getRowByIndex(rowIndex: number): any {
    // Clone the model because we don't want manipulate the reel row
    if (this.model['subfile'] && this.model['subfile'][rowIndex]) {
      return JSON.parse(JSON.stringify(this.model['subfile'][rowIndex]));
    }
  }

  /**
   * Select or unselect
   */
  toggleFromSelection(rowIndex: number): void {
    if (!this.isInSelection(rowIndex)) {
      this.addToSelection(rowIndex);
    } else {
      this.removeFromSelection(rowIndex);
    }
  }

  /**
   * Removes a row to the grid selection.
   * @param rowIndex index of the row to add to the selection.
   */
  removeFromSelection(rowIndex: number): void {
    if (this.selectionMode === 'single') {
      this.selectedRows = undefined;
    } else if (this.selectionMode === 'multiple') {
      let result = -1;
      this.selectedRows.forEach((element: any, index: any): any => {
        if (JSON.stringify(element) === JSON.stringify(this.getRowByIndex(rowIndex))) {
          result = index;
          return;
        }
      });
      this.selectedRows.splice(result, 1);
    }
    // Call selection management method
    this.onSelectionChange({ value: this.selectedRows });
  }

  /**
   * Adds a row to the grid selection.
   * @param rowIndex index of the row to add to the selection.
   */
  addToSelection(rowIndex: number): void {
    if (this.selectionMode === 'single') {
      this.selectedRows = this.getRowByIndex(rowIndex);
    } else if (this.selectionMode === 'multiple') {
      this.selectedRows.push(this.getRowByIndex(rowIndex));
    }
    // Call selection management method
    this.onSelectionChange({ value: this.selectedRows });
  }

  /**
   * Check if a grid row is in the current selection.
   * @param rowIndex index of the row to add to the selection.
   */
  isInSelection(rowIndex: number): boolean {
    const row = this.getRowByIndex(rowIndex);
    if (this.selectionMode === 'single') {
      return JSON.stringify(this.selectedRows) === JSON.stringify(row);
    } else if (this.selectionMode === 'multiple') {
      // Get the index of the cliked row in the current selection
      let result = false;
      for (const element of this.selectedRows) {
        if (JSON.stringify(element) === JSON.stringify(row)) {
          result = true;
          break;
        }
      }
      return result;
    }
    return false;
  }

  /**
   * Returns the options with translated labels
   */
  getDropdownValues(options: any, field: string): any {
    // First if dropdownOptions are in the model for this field
    if (this.model.dropdownOptions && this.model.dropdownOptions[field]) {
      // Clear options (if they are declared in screen definition)
      options = [];

      // Add to options
      this.model.dropdownOptions[field].forEach((option: any, _index: number): void => {
        options.push({ value: option.code, label: option.description });
      });
    }

    return options;
  }

  // With PrimeReact 6.6.0 the ColumnSelection is now typed in PrimeReact and they support only 2 types !
  convertSelectionModeForPrime(frescheSelectionMode: string): any {
    switch (frescheSelectionMode) {
      case 'single':
        return 'single';
      case 'multiple':
        return 'multiple';
      case 'none':
      default:
        return 'multiple';
    }
  }

  /**
   * return the field name of the default sortField
   */
  getSortField(): string {
    return '';
  }

  /**
   * return the default sort order
   */
  getSortOrder(): DataTableSortOrderType {
    return undefined;
  }

  /**
   * gridSort is used then the grid is in lazy mode (so the normal mode in the frontend)
   * event.sortField = FieldName to sort
   * sortOrder = Sort order
   * 
   */
  gridSort(_event: any): any {
    // We do nothing on our grid, let the end developer to override this method and do his own logic
    return undefined;
  }

  /**
   * When subfileHasAllData is true (when the grid is not lazy load)
   * event.field = Field to sort
   * event.order = Sort order
   */
  columnSort(event: any): any {
    const getColumnIndex = (fieldName: string): number => {
      return this.model['subfileFields'].filter((data: any) => data.field === fieldName).map((obj: any) => obj.index);
    }

    // Internal sort
    function compareData(a: any, b: any): number {
      let result = 0;

      if (a.fields[getColumnIndex(event.field)] < b.fields[getColumnIndex(event.field)]) {
        result = 1;
      }

      if (a.fields[getColumnIndex(event.field)] > b.fields[getColumnIndex(event.field)]) {
        result = -1;
      }

      return result * event.order;
    }

    return this.model['subfile'].sort(compareData);
  }

  /**
   * A function to implement custom export. Need to return string value.
   * event.data: Field data.
   * event.rows: Column field.
   */
  gridExport(_event: any): any {
    // This is intentional;
  }

  /**
   *
   */
  exportToCSV(selectionOnly: boolean): any {
    // this.dt.exportCSV({ selectionOnly }); // Export on grid doesn't work because our data is under a JSON structure

    // Return the data of the subfile into a simple array.  Check if user wants only the selected records.
    const selectedData = this.model['subfile'].filter((data: any) => data.recordSelected === 'Y' || selectionOnly === false).map((obj: any) => obj.fields);

    // 1) get read only column
    const visibleColumns = this.getColumnsUserConfig().filter((col: any) => !this.hiddenColumn(col[0].field)).map((obj: any) => this.getRowDataFieldIndex(obj[0].field).toString());

    const dataToExport: any[] = [];

    // 2) Remove readonly from each row
    selectedData.forEach((row: any) => {
      const newElement = {};
      visibleColumns.forEach((vCol: any) => {
        newElement[vCol] = row[vCol];
      });
      dataToExport.push(newElement);
    });

    this.exportToCsvFile('download.csv', dataToExport);

  }

  /**
   * return index of the defined field in subfileFields property.  Index is important because we can have 2 or more fields in a cell
   */
  getRowDataFieldIndex(field: string): number {
    if (this.model !== undefined) {
      if (this.model['subfile'] !== null && this.model['subfile'] !== undefined) {
        return this.model['subfileFields'].filter((data: any) => data.field === field).map((obj: any) => obj.index);
      }
    }

    return 0;
  }

  /**
   *
   */
  exportToCsvFile(filename: string, rows: object[]): void {
    if (!rows || !rows.length) {
      return;
    }

    const separator = ',';
    const keys = Object.keys(rows[0]);
    const titles: any[] = [];

    keys.forEach((k: any) => {
      const title = this.getColumnsUserConfig().filter((col: any) => this.getRowDataFieldIndex(col[0].field).toString() === k).map((obj: any) => obj[0].label !== '' ? obj[0].label : obj[0].header1);
      titles.push(title);
    });

    const csvContent =
      titles.join(separator) +
      '\n' +
      rows.map((row: any) => {
        return keys.map((k: any) => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');

    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  /**
   *
   */
  resetColumnOrder(): any {
    localStorage.removeItem(`grid-order-columns-${this.props.screenDef.package}-${this.props.screenDef.deviceName}`);
    this.forceUpdate();
  }

  /**
   *
   */
  onColReorder(event: any): any {
    const columns: any[] = [];

    event.columns.forEach((element: any) => {
      if (element.props.className !== 'no-default-select') {
        columns.push({ field: element.props.field });
      }
    });

    localStorage.setItem(`grid-order-columns-${this.props.screenDef.package}-${this.props.screenDef.deviceName}`, JSON.stringify(columns));
  }

  /**
   *  return gridDefinition columns OR the saved columns definition (if the user has moved a column or hide/show a column)
   */
  getColumnsUserConfig(): any {
    const columnsSaved = localStorage.getItem(`grid-order-columns-${this.props.screenDef.package}-${this.props.screenDef.deviceName}`);

    if (columnsSaved !== null) {

      let newColumnOrder: any[] = [];
      const columns = JSON.parse(columnsSaved);

      columns.forEach((element: any) => {
        this.gridDefinition.columns.forEach((colDef: any) => {
          if (element.field === colDef[0].field) {
            newColumnOrder.push(colDef)
          }
        });
      });

      return newColumnOrder
    } else {
      return this.gridDefinition.columns;
    }
  }

  // ====================================
  //  GRID RENDERING
  // ====================================

  /**
   * Render the inputs for the filter cell
   */
  getCellFilter(filter: any, index: any): any {
    if (this.model['fields'][filter.field] !== undefined) {
      if (filter.type === 'string' || filter.type === 'long' || filter.type === 'number') {
        const keyFilter = (filter.type === 'number') ? 'num' : '';
        const textAlign = (filter.type === 'number') ? 'right' : 'left';
        return (
          <React.Fragment key={'filter' + index}>
            {filter.label && filter.label !== '' && (<label className="filter-label">{filter.label}</label>)}

            <FTextInput
              className={`${this.getAddClass(filter.addClass)}`}
              id={filter.field}
              readonly={filter.condition ? filter.condition['readonly'] : false}
              value={this.model['fields'][filter.field]}
              maxlength={filter.displayFormat && filter.displayFormat.textLength && filter.displayFormat.textLength ? filter.displayFormat.textLength : -1}
              required={filter.required}

              displayFormat={filter.displayFormat}
              promptable={filter.lookupFlag}
              suffix={filter.options ? filter.options.suffix : null}

              onValueChange={(newValue: any): void => {
                if (filter.displayFormat && filter.displayFormat.textCase && filter.displayFormat.textCase.toLowerCase() === 'uppercase') {
                  newValue = newValue.toUpperCase();
                }
                this.onFilterValueChange(newValue, filter.field);
              }}

              color={filter.condition ? filter.condition['color'] : ''}
              highintensity={filter.condition ? filter.condition['highintensity'] : ''}
              protect={filter.condition ? filter.condition['protect'] : ''}
              reverseimage={filter.condition ? filter.condition['reverseimage'] : ''}
              underline={filter.condition ? filter.condition['underline'] : ''}
              hidden={filter.condition ? filter.condition['hidden'] : ''}

              error={this.props.filterErrors && this.props.filterErrors[filter.field] ? this.props.filterErrors[filter.field] : ''}
            />
          </React.Fragment>
        );
      } else if (filter.type === 'dropdown') {
        return (
          <React.Fragment key={'filter' + index}>
            {filter.label && filter.label !== '' && (<label className="filter-label">{filter.label}</label>)}
            <FSelectInput
              className={`${this.getAddClass(filter.addClass)}`}
              key={'DropDown' + index}
              id={filter.field}
              name={filter.field}
              placeholder={filter.placeholder}
              value={this.model['fields'][filter.field]}
              values={this.getDropdownValues(filter.values, filter.field)}
              label={filter.labelLocation === 'above' ? filter.header1 : filter.label}
              maxlength={filter.displayFormat && filter.displayFormat.textLength ? filter.displayFormat.textLength : filter.textLength}
              required={filter.required}
              displayFormat={filter.displayFormat}
              onValueChange={(newValue: any): void => {
                this.onFilterValueChange(newValue, filter.field);
              }}
              promptable={filter.lookupFlag}
              customValue={filter.customValue}
              suffix={filter.options ? filter.options.suffix : null}
              labelOnTop={filter.labelLocation === 'above'}
              color={filter.condition ? filter.condition['color'] : ''}
              readonly={filter.condition ? filter.condition['readonly'] : ''}
              highintensity={filter.condition ? filter.condition['highintensity'] : ''}
              protect={filter.condition ? filter.condition['protect'] : ''}
              reverseimage={filter.condition ? filter.condition['reverseimage'] : ''}
              underline={filter.condition ? filter.condition['underline'] : ''}
              hidden={filter.condition ? filter.condition['hidden'] : ''}

              error={this.props.filterErrors && this.props.filterErrors[filter.field] ? this.props.filterErrors[filter.field] : ''}
            />
          </React.Fragment>
        );
      } else if (filter.type === 'datetime' || filter.type === 'date' || filter.type === 'time') {
        return (
          <React.Fragment key={'filter' + index}>
            {filter.label && filter.label !== '' && (<label className="filter-label">{filter.label}</label>)}
            <FDateTimeInput
              className={`${this.getAddClass(filter.addClass)}`}
              id={filter.field}
              dateFormat={this.dateFormat}
              timeSeparator={this.timeSeparator}
              showSeconds={this.showSeconds}
              type={filter.type}
              readonly={filter.condition ? filter.condition['readonly'] : false}
              value={this.model['fields'][filter.field] !== '0001-01-01' ? this.model['fields'][filter.field] : null}
              promptable={false}
              onValueChange={(newValue: any): void => { this.onFilterValueChange(newValue, filter.field); }}

              color={filter.condition ? filter.condition['color'] : ''}
              highintensity={filter.condition ? filter.condition['highintensity'] : ''}
              protect={filter.condition ? filter.condition['protect'] : ''}
              reverseimage={filter.condition ? filter.condition['reverseimage'] : ''}
              underline={filter.condition ? filter.condition['underline'] : ''}
              hidden={filter.condition ? filter.condition['hidden'] : ''}

              error={this.props.filterErrors && this.props.filterErrors[filter.field] ? this.props.filterErrors[filter.field] : ''}
            />
          </React.Fragment>
        );
      }
    }
    // return (<span key={'filter' + index} />);
    return (<React.Fragment key={'filter' + index} />);
  }

  /**
   * Renders the filters of a single cell
   * @param cell screen definition section representing a grid cell
   */
  getCellFilters(cell: any, index: any): any {
    if (cell.filters && cell.filters.length) {
      return (
        <React.Fragment key={index}>
          {
            cell.filters.map((filter: any, i: any): any => {
              return this.getCellFilter(filter, i);
            })
          }
        </React.Fragment>
      );
    } else {
      return (<React.Fragment key={index} />);
    }
  }

  /**
   * Renders the headers of the grid
   */
  getGridDataTableHeader(): any {
    const onColumnToggle = (event: { value: any; }) => {
      let selectedColumns = event.value;

      const columns: any[] = [];

      selectedColumns.forEach((element: any) => {
        columns.push({ field: element.field });
      });

      this.visibleColumns = columns;

      localStorage.setItem(`grid-visible-columns-${this.props.screenDef.package}-${this.props.screenDef.deviceName}`, JSON.stringify(columns));

      this.forceUpdate();
      this.setupGrid();
    }

    return (
      <React.Fragment>
        {this.gridDefinition.actions && (
          <div className="action-buttons">
            <div>
              <div className="actions">
                {!this.props.actionsAsMenu &&
                  this.gridDefinition.actions.map((action: any, index: any): any => {
                    return this.getActionButtons(action, index);
                  })
                }
              </div>

              <Tooltip target=".export-buttons>button" position="bottom" />
              <div className="right-buttons-container">
                {((this.model.subfileHasAllData || this.props.screenDef.gridDefinition.exportable) && this.props.screenDef.gridDefinition.exportable !== false) && (
                  <div className="export-buttons">
                    <Button type="button" icon="pi pi-file" onClick={(): any => this.exportToCSV(false)} className="p-mr-2" data-pr-tooltip="Export" />
                    <Button type="button" icon="pi pi-file-excel" onClick={(): any => this.exportToCSV(true)} className="p-button-info p-ml-auto" data-pr-tooltip="Export Selection Only" />
                  </div>
                )}
                {(this.props.screenDef.gridDefinition.toggleColumns) && (
                  <div>
                    <MultiSelect value={this.visibleColumns} options={this.allAvailableColumns} optionLabel="field" onChange={onColumnToggle} style={{ width: '10em' }} filter={false} fixedPlaceholder={true} placeholder="Columns" showSelectAll={false} />
                  </div>
                )}
                {(this.props.screenDef.gridDefinition.reorderableColumns) && (
                  <div className="export-buttons">
                    <Button type="button" icon="pi pi-ellipsis-v" onClick={(event) => this.ellipsisMenu.toggle(event)} className="p-button-info p-ml-auto" data-pr-tooltip="Reset column order" aria-controls="ellipsisMenu" aria-haspopup />
                    <Menu model={this.ellipsisMenuItems} popup ref={el => this.ellipsisMenu = el} id="ellipsisMenu" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  /**
   *
   */
  getActionButtons(action: any, index: any): any {
    if (!this.hiddenActionButton(action)) {
      const conditions = (this.props && this.props.conditions && this.props.conditions.grid && this.props.conditions.grid.actions) ? this.props.conditions.grid.actions[action.key] : undefined;
      const label = (conditions && conditions.label) ? conditions.label : action.label;
      const actionOnRowSelect = this.props.screenDef.gridDefinition.actionOnRowSelect && this.props.screenDef.gridDefinition.actionOnRowSelect === action.key;

      return (
        <FActionButton className={`${actionOnRowSelect ? 'actionOnRowSelect' : ''} ${this.getAddClass(action.addClass)}`} key={'FActionButton' + index} tooltip={action.tooltip ? action.tooltip : label} tooltipOptions={action.tooltipOptions} icon={action.icon} iconPos={action.iconPos} title={label} disabled={action.disable} image={action.image} action={(): any => this.onActionButtonClick(action)} />
      );
    }
  }
  /**
   * 
   * @param props Prime Grid props (information related to the grid, row and col)
   * @returns Because Prime grid's rowIndex is sequential on each page, we need to take the rowIndex - the first index in the page to have a valid index in the data.  Because data 
   *          is always the current displayed data.  Example: grid display 10 rows, when you go on page 2 then the first rowindex is 10 and the first displayed is 10.  Our data is a array from 0 to 10, so 10-10 = 0, we are on the good record.
   */
   getDataRowIndex(props: any): number {
    return props.rowIndex - props.props.first;
  }


  /**
   * Generate the content for a cell
   */
  cellBodyTemplate(rowData: any, props: any): any {
    const colIndex = this.gridDefinition.columns.map((obj: any) => obj[0].field).indexOf(props.field);
    const colInformation = this.gridDefinition.columns[colIndex][0];
    const cellFieldIndex = this.model['subfileFields'].filter((data: any) => data.field === props.field).map((obj: any) => obj.index)

    // If a custom component is assigned to override the default rendering
    let colCustomComponent: any;

    if (this.props.customComponents) {
      const colCustomComponentIndex = this.props.customComponents.map((obj: any) => obj.field).indexOf(props.field);

      if (colCustomComponentIndex !== -1) {
        colCustomComponent = this.props.customComponents[colCustomComponentIndex].component;
      }
    }
    return (
      <div className="selection-box" onClick={(event: any): any => this.selectRow(event, this.getDataRowIndex(props))} >
        <FGridCell
          singleSelectionMode={this.selectionMode === 'single'}
          definition={colInformation}
          custom={colCustomComponent}
          row={this.getDataRowIndex(props)}
          data={rowData['fields'][cellFieldIndex]}
          dropdownOptions={this.model['dropdownOptions']}
          fieldName={props.field}
          maxlength={colInformation.displayFormat && colInformation.displayFormat.textLength ? colInformation.displayFormat.textLength : (colInformation.textLength ? colInformation.textLength : undefined)}
          dateFormat={this.dateFormat}
          timeSeparator={this.timeSeparator}
          showSeconds={this.showSeconds}
          errorMessage={this.getFieldErrorMessage(rowData['messageDTO'], props.field)}
          condition={this.getCellConditions(colInformation, this.getDataRowIndex(props))}
          dataChange={(newValue: any): any => this.onCellValueChange(newValue, cellFieldIndex, rowData, this.getDataRowIndex(props))}
          onFocus={(): void => { if (this.props.onTableFieldFocus) { this.props.onTableFieldFocus(colInformation.field, colInformation.fieldId, this.getDataRowIndex(props)); } }}
          onCmdkeyClick={(_e: any, cmdkey: string): any => { if (this.props.onTableCmdkeyClick) { this.props.onTableCmdkeyClick(colInformation.field, colInformation.fieldId, cmdkey, this.getDataRowIndex(props)) } }}
        />
      </div>
    );
  }

  /**
   *
   */
  getColumnHeader(col: any): any {
    return (
      <React.Fragment>
        {this.hasFilters && (
          <React.Fragment>
            {
              col.filters && col.filters.length > 0 && (
                <div className="filter">
                  {this.getCellFilters(col, 0)}
                </div>
              )
            }
            {(!col.filters || (col.filters && col.filters.length === 0)) && (
              <div className="filter empty">
                <span />
              </div>
            )}
          </React.Fragment>
        )}
        {!this.hiddenColumn(col.field) && (
          <React.Fragment>
            <div className="header-title" style={{ textAlign: (this.isRightAlign(col.type)) ? 'right' : 'left' }}>
              {col.header1}
            </div>
            {col.header2 && col.header2 !== '' && (
              <div className="header-title" style={{ textAlign: (this.isRightAlign(col.type)) ? 'right' : 'left' }}>
                {col.header2}
              </div>
            )}
            {col.header3 && col.header3 !== '' && (
              <div className="header-title" style={{ textAlign: (this.isRightAlign(col.type)) ? 'right' : 'left' }}>
                {col.header3}
              </div>
            )}
          </React.Fragment>
        )
        }

      </React.Fragment>
    );
  }

  /**
   * Return classname for the row
   */
  rowClass(rowData: any): any {
    let rowClass = { 'row-error': false };

    if (rowData.messageDTO) {
      rowData.messageDTO.forEach((error: any): any => {
        // if messageDTO doesn't have a field then it's for the entire row
        if (error.fieldsName.length === 0) {
          rowClass = { 'row-error': true };
          return;
        }
      });
    }
    return rowClass;
  }

  /**
   * Return classname for the row
   */
  getDataTableClass(): any {
    return this.model && (this.model.subfileHasAllData || this.props.screenDef.gridDefinition.pageOfPages) ? 'show-full-paginator' : '';
  }

  /**
   * Create a popup menu for one row.  Each row have this own popup menu (otherwise we have a visual bug when we click outside the popup but on another gear icon to show second popup)
   * props param is the datatable.row
   */
  actionMenuBodyTemplate(ev: any, props: any): React.ReactElement {
    const rowIndex = this.getDataRowIndex(props);
    const newref = React.createRef<any>();
    this.actionMenuMap.set(rowIndex, newref);

    return (
      <React.Fragment>
        <Menu
          id={`grid-actions-menu-${rowIndex}`}
          baseZIndex={99999}
          model={this.getActionMenuItems()}
          popup={true}
          ref={newref}
        />
        <Button
          icon={this.props.actionMenuIcon ? this.props.actionMenuIcon : 'pi pi-cog'}
          id={`action-menu-button-on-row-${rowIndex}`}
          className={'p-button-rounded p-button-text grid-menu-btn'}
          onClick={(event: any): void => {
            // Retrieve the associated Menu by Ref
            const actionMenu = this.actionMenuMap.get(rowIndex);
            (actionMenu as React.RefObject<any>).current.show(event);

            // Check if record is not selected.  If it's selected then do nothing
            if (ev.recordSelected !== 'Y') {
              this.selectRow(event, rowIndex);
            }
          }}
        />
      </React.Fragment>
    );
  }

  /**
   *
   */
  getPaginatorTemplate(): string {
    let template = 'PrevPageLink PageLinks NextPageLink';

    if (this.model && this.model.subfileHasAllData) {
      template = 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink';
    } else if (this.model && this.gridDefinition.pageOfPages) {
      template = 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink';
    }
    return template;
  }

  /**
   *
   */
  getCurrentPageReportTemplate(): string {
    let template = '';

    if ((this.model && this.model.subfileHasAllData) || (this.model && this.gridDefinition.pageOfPages)) {
      template = 'Showing page {currentPage} of {totalPages}';
    }

    return template;
  }

  /**
   *  Generate the body of the grid (DataTable)
   */
  getGridDataTable(): React.ReactElement {
    const selectionColumn = (this.selectionMode !== 'none' /*&& !this.props.actionsAsMenu */) && (
      <Column className="no-default-select" reorderable={false} selectionMode={this.convertSelectionModeForPrime(this.selectionMode)} style={{ width: '45px', maxWidth: '45px' }} />
    );

    const actionsMenuColumn = this.props.actionsAsMenu && (
      <Column className="no-default-select" reorderable={false} style={{ minWidth: '45px', maxWidth: '45px', textAlign: 'center' }} body={this.actionMenuBodyTemplate} resizeable={false} />
    );

    const dynamicColumns = this.getColumnsUserConfig().map((col: any, index: any): any => {
      if (!this.hiddenColumn(col[0].field)) {
        return <Column key={index} className={`${this.getAddClass(col[0].addClass)}`} style={this.getColumnWidth(col[0])} field={col[0].field} sortField={col[0].field} sortFunction={this.columnSort} header={this.getColumnHeader(col[0])} columnKey={col[0].field} body={this.cellBodyTemplate} sortable={this.getSortable(col[0])} exportable={this.getExportable(col[0])} />;
      }
    });

    // A bug in the grid force to declare the grid twice.  Because when we change the mode, then the grid failed
    if (this.model.subfileHasAllData) {
      return (
        <React.Fragment>
          {/* Hack for the grid, if not used here then the grid never goes on the firstRecordIndex */}
          <div style={{ display: 'none' }}>{this.firstRecordIndex}</div>
          <DataTable
            ref={(el: any): void => this.dt = el}
            autoLayout={true}
            resizableColumns={true}
            columnResizeMode={'fit'}
            reorderableColumns={this.gridDefinition.reorderableColumns}
            onColReorder={this.onColReorder}
            totalRecords={this.totalRecords}
            paginatorTemplate={this.getPaginatorTemplate()}
            currentPageReportTemplate={this.getCurrentPageReportTemplate()}
            rowsPerPageOptions={[10, 20, 50]}
            pageLinkSize={this.gridDefinition.pageOfPages ? 5 : 1}
            paginator={true}

            lazy={false} // When lazy, you control everything (sortFunction not called when lazy)
            onPage={this.getPageAllData}

            first={this.firstRecordIndex}
            rows={this.numRows}

            value={this.model['subfile']}
            header={this.getGridDataTableHeader()}
            selection={this.selectedRows}
            dataKey="index"

            onSelectionChange={(e: any): void => this.onSelectionChange(e)}
            exportFunction={this.gridExport}
            rowClassName={this.rowClass}
            responsiveLayout={"scroll"}

            sortMode={'single'}
            sortField={this.getSortField()}
            sortOrder={this.getSortOrder()}
          >
            {selectionColumn}
            {(this.props.actionMenuPos === undefined || this.props.actionMenuPos === 'left') && (
              actionsMenuColumn
            )}
            {dynamicColumns}
            {(this.props.actionMenuPos && this.props.actionMenuPos === 'right') && (
              actionsMenuColumn
            )}
          </DataTable >
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {/* Hack for the grid, if not used here then the grid never goes on the firstRecordIndex */}
          <div style={{ display: 'none' }}>{this.firstRecordIndex}</div>
          <DataTable
            ref={(el: any): void => this.dt = el}
            autoLayout={true}
            resizableColumns={true}
            columnResizeMode={'fit'}
            reorderableColumns={this.gridDefinition.reorderableColumns}
            onColReorder={this.onColReorder}
            totalRecords={this.totalRecords}
            paginatorTemplate={this.getPaginatorTemplate()}
            currentPageReportTemplate={this.getCurrentPageReportTemplate()}
            rowsPerPageOptions={[10, 20, 50]}
            pageLinkSize={this.gridDefinition.pageOfPages ? 5 : 1}
            paginator={true}

            lazy={true} // Different than the subfileHasAllData declaration
            onPage={this.getPage} // Different than the subfileHasAllData declaration
            onSort={this.gridSort} // Different than the subfileHasAllData declaration

            first={this.firstRecordIndex}
            rows={this.numRows}

            value={this.model['subfile']}
            header={this.getGridDataTableHeader()}
            selection={this.selectedRows}
            dataKey="index"

            onSelectionChange={(e: any): void => this.onSelectionChange(e)}
            exportFunction={this.gridExport}
            rowClassName={this.rowClass}
            responsiveLayout={"scroll"}

            sortMode={'single'}
            sortField={this.getSortField()}
            sortOrder={this.getSortOrder()}
          >
            {selectionColumn}
            {(this.props.actionMenuPos === undefined || this.props.actionMenuPos === 'left') && (
              actionsMenuColumn
            )}
            {dynamicColumns}
            {(this.props.actionMenuPos && this.props.actionMenuPos === 'right') && (
              actionsMenuColumn
            )}
          </DataTable >
        </React.Fragment>
      );
    }
  }

  /**
   * Renders the component
   */
  render(): React.ReactElement {
    return (
      <div className={`f-grid ${this.props.actionsAsMenu ? 'has-actions-menu' : ''} ${this.getDataTableClass()} ${this.getAddClass(this.props.screenDef.gridDefinition.addClass)}`} ref={(el: any): any => this.table = el}>
        {this.getGridDataTable()}
      </div>
    );
  }
}
