import * as React from 'react';
import FInput, { FInputProps } from '../f-input/f-input';
import { Dropdown, DropdownAppendToType } from 'primereact/dropdown';
import '../f-input/f-input.css';
import './f-select-input.css';

export interface FSelectInputProps extends FInputProps {
  values: object[];
  customValue?: boolean;
  floatLabel?: boolean;
  displayValueOnly?: boolean;
  appendTo?: DropdownAppendToType;
}

export default function FSelectInput(props: FSelectInputProps): React.ReactElement {
  const baseInput = new FInput();

  React.useEffect((): any => {
    if (props.autofocus) {
      setTimeout(() => {
        baseInput.focusElement(props.id);
      }, 100);
    }

    if (props.values && props.displayValueOnly) {
      for (let value of props.values) {
        value['label'] = value['value'];
      }
    }
  }, []);

  const renderInput = (): React.ReactElement => {
    return (
      <React.Fragment>
        <div className={baseInput.getInputWrapperClasses(props) + ' f-select'}>
          {props.label && <label htmlFor={props.name}>{props.label}</label>}
          <div className={baseInput.getResizableContainerClasses(props) + ' resizable-container'}>

            <Dropdown
              className={'p-dropdown ' + (props.floatLabel ? '' : 'floatLabel')}
              id={props.id}
              appendTo={props.appendTo === undefined ? 'self' : props.appendTo} // appendTo should be 'self' when dropdown is on a popup screen to avoid scrolling effect problem.
              placeholder={props.label}
              options={props.values}
              value={props.value}
              disabled={props.protect}
              tooltip={props.tooltip}
              editable={props.customValue ? props.customValue : false}
              // tooltipPosition={props.tooltipPosition === undefined ? 'bottom' : props.tooltipPosition}
              // tooltipStyleClass={props.tooltipStyleClass}
              // tooltipEvent={props.tooltipEvent === undefined ? 'hover' : props.tooltipEvent}
              onChange={(e: any): void => { baseInput.onChange(e.value, props.onValueChange); }}
              onMouseDown={(e: any): void => { baseInput.onFocus(e, props.onFocus); }}
            />

            {props.required && <span className="required-symbol">*</span>}
          </div>
        </div>
        {props.error && props.error !== '' && <span className="error-msg">{props.error}</span>}
      </React.Fragment>
    );
  };

  return renderInput();
}
